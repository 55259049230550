<template>
  <v-container fluid>
    <h1 class="title">
      Поръчка {{ $route.params.order_id }}
    </h1>
    <v-card>
      <v-card-text>
        <app-order-list :view="true" :order_items="order"></app-order-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Order",
  components: {
    appOrderList: () => import('@/components/OrderList/OrderList')
  },
  data: () => ({
    order: []
  }),
  methods: {
    fetchOrder() {
      let self = this;
      this.get(`/orders/order/${this.$route.params.order_id}/get`).then(r => {
        r.orders.forEach(order=>{
          order.good.quantity = order.Qtty
          self.order.push(order.good)
        })
      })
    }
  },
  mounted() {
    this.fetchOrder()
  }

}
</script>

<style scoped>

</style>