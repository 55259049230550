import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Partners from "@/views/Partners/Partners";
import Orders from "@/views/Orders/Orders";
import Login from "@/views/Auth/Login";
import Register from "@/views/Auth/Register";

import store from "@/store/index"
import CreateOrder from "@/views/Orders/CreateOrder";
import Forbidden from "@/views/Error/Forbidden";
import Order from "@/views/Orders/Order";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,

  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
    meta: { requiresAuth: true,requiresAdmin:true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders/order/create',
    name: 'CreateOrder',
    component: CreateOrder,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders/order/:order_id',
    name: 'Order',
    component: Order,
    meta: { requiresAuth: true }
  },
  {
    path: '/403',
    name: '403',
    component:Forbidden,
    meta: { requiresAuth: true }
  },
  {
    path: '/password-reset',
    name: 'passwordResetRequest',
    component:()=>import('@/views/PasswordReset/PasswordReset'),
  },
  {
    path: '/password-reset/code/:code',
    name: 'passwordReset',
    component:()=>import('@/views/PasswordReset/PasswordReset'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    let now = new Date();
    if (now > localStorage.expires_in) {
      store.commit('setAuth', false)
      localStorage.setItem('userLevel','')
      next({
        path: '/',
      })
    } else {
      store.state.auth = true
      next()
    }
  }
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // this route requires auth, check if logged in

    if (parseInt(localStorage.userLevel) !== 2 ) {
      store.commit('setAdmin',false)
      next({
        path: '/403',
      })
    } else {
      next()
    }
  }
  else {
    next() // make sure to always call next()!
  }
})

export default router
