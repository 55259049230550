import Vue from 'vue'
import Vuex from 'vuex'

import mix from "@/mixins/mixin";
import router from "@/router";


// eslint-disable-next-line no-unused-vars


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: false,
        activate: false,
        expires_in: "",
        isAdmin:false,
        error:"",
        user:null,
        loading:false
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setAuth(state, auth) {
            state.auth = auth
        },
        setActivate(state, activated) {
            state.activated = activated
        },
        setExpiresIn(state, expires_in) {
            state.expires_in = expires_in
        },
        setAdmin(state, isAdmin) {
            state.isAdmin = isAdmin
        },
        setError(state, error) {
            state.error = error
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    getters:{
        isAdmin:state => {
            return state.isAdmin
        }

    },

    actions: {
        login({state,commit}, user) {
            mix.methods.post('/login', user).then(r => {
                const now = new Date()
                const expirationDate = new Date(now.getTime() + r.expires_in * 60)
                localStorage.setItem('access_token', r.access_token);
                localStorage.setItem('userLevel', r.user.userLevel);
                localStorage.setItem('expires_in',expirationDate)
                state.expires_in = expirationDate;
                state.auth = true;
                state.isAdmin = r.user.userLevel===2;
                state.user = r.user
                router.push('/orders');
            }).catch(err=>{
               commit('setError',err.response.data.error)

            })
        },
        register({commit}, user) {
            mix.methods.post('/register', user).then(r => {
                commit('setError',r.message,false);
                router.push('/');
            }).catch(err=>{
              commit('setError',err.response.message.errors.email[0],true);
            })
        },
        checkAuth({state,commit}) {
            mix.methods.get(`/me`).then(r => {
                //console.log(r);
                state.auth = true;
                commit('setAdmin',r.userLevel === 2)
               // localStorage.setItem('userLevel', r.userLevel);
                commit('setUser',r)
              localStorage.setItem('userLevel',r.userLevel)
            })
        },
        logout({state}){
            mix.methods.post('/logout',{}).then(()=>{
                localStorage.setItem('access_token',null)
                localStorage.setItem('userLevel',null)
                state.auth = false;
                state.user = null;
                state.isAdmin = false
                mix.methods.redirect('/')
            })
        },
        refreshToken() {
            mix.methods.post('auth/refresh', {}).then(r => {
                localStorage.setItem('access_token', r.access_token)
                const now = new Date()
                const expirationDate = new Date(now.getTime() + r.expires_in * 60)
                localStorage.setItem('expires_in', expirationDate);
            })
        },
    },


})
