<template>
  <v-app id="inspire">

    <v-app-bar app clipped-left>
<!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>-->
      <v-avatar contain @click="redirectWithRouter('/orders')">
        <v-img :src="require('@/assets/logo_app.png')" width="48" height="48" contain></v-img>
      </v-avatar>

      <v-toolbar-title class="pl-5 ">
        Индустриално пране
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="user">
        {{ user.name }}
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
        app
        v-model="drawer"

        clipped
        :mini-variant="$vuetify.breakpoint.smAndUp"
        :mini-variant-width="$vuetify.breakpoint.smOnly?'9vw':'5vw'"

        :permanent="$vuetify.breakpoint.smAndUp"

        v-if="auth"
    >
      <v-list>
        <template v-for="(item,i) in items">
          <v-list-item :key="i" :to="item.to" color="#31A4C1" v-if="item.requiresAdmin?isAdmin:true" exact>
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>
              mdi-door-open
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Изход
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <app-notifications></app-notifications>
      <app-loader v-if="loading"></app-loader>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>


import {mapActions} from 'vuex'

export default {
  name: 'App',
  components: {
    appNotifications: () => import('@/components/Notifications/Notifications'),
    appLoader: () => import('@/components/Loaders/Loader')
  },
  data: () => ({
    drawer: true,
    items: [
      {
        icon: 'mdi-account-multiple',
        title: 'Партньори',
        requiresAdmin: true,
        to: '/partners'
      },
      {
        icon: 'mdi-plus',
        title: 'Създай поръчка',
        requiresAdmin: false,
        to: '/orders/order/create'
      },
      {
        icon: 'mdi-book',
        title: 'Справки',
        requiresAdmin: false,
        to: '/orders'
      },
    ],
  }),
  methods: {
    ...mapActions(['checkAuth', 'logout']),
    isAuthorized(item) {
      if (item.requiresAdmin) {
        if (!this.isAdmin) {
          return false
        }
      }
      return true;
    }
  },
  computed: {
    auth: {
      get() {
        return this.$store.state.auth
      }
    },
    activated: {
      get() {
        return this.$store.state.activate
      }
    },
    isAdmin: {
      get() {
        return this.$store.state.isAdmin
      }
    },
    loading: {
      get() {
        return this.$store.state.loading
      }
    },
    user: {
      get() {
        return this.$store.state.user
      }
    },
    error: {
      get() {
        return this.$store.state.error
      },
      set(val) {
        this.$store.commit('setError', val)
      }
    }
  },
  mounted() {
    this.checkAuth();
  },
  watch: {
    error(val) {
      if (val) {
        this.showAlert(val, true);
        this.error = ""
      }

    }
  }
};
</script>
<style>
.v-avatar:hover {
  cursor: pointer;
}
</style>
