import axios from 'axios'

let moment = require('moment');
moment.locale('bg');


let mix = {
    methods: {
        ajaxCall(method, url, params = '', alert = true) {
            if (!url || !method) {
                console.log('Please read the instruction')
                return 'Please read the instruction'
            }
            let config = {
                "method": method,
                "url": url,
                "maxRedirects": 2,
            }
            config['headers'] = {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': ' Bearer ' + localStorage.access_token,

            }
            if ((method == 'POST')) {
                if (!params) {
                    console.log('POST without body')
                    return 'POST without body'
                } else {
                    config["data"] = params;
                    //config["headers"] = {'content-type': 'application/x-www-form-urlencoded'};

                }
            }
            if ((method == 'PATCH')) {
                if (!params) {
                    console.log('POST without body')
                    return 'POST without body'
                } else {
                    config["data"] = params;
                    //config["headers"] = {'content-type': 'application/x-www-form-urlencoded'};

                }

            }
            if ((method == 'GET')) {
                if (params) {
                    config["params"] = params
                }
            }
            if ((method == 'DELETE')) {
                if (params)
                    config["data"] = params;
                //config["headers"] = {'content-type': 'application/x-www-form-urlencoded'};

            }

            return axios(config)
                .then((response) => {
                    try {
                        if (response.data != '') {
                            JSON.parse(response.data)
                        }
                    } catch (error) {
                        if (typeof response.data == "string") {
                            console.error('Result is not JSON. ' + error.message + ' response: ' + response.data)
                        }
                    }
                    return new Promise((resolve) => {
                        resolve(response.data)
                    })
                })
                .catch((err) => {
                    return new Promise((resolve, reject) => {
                        if (err.response.status == 401) {
                            if (alert) {
                               // this.showAlert('Сесията ви изтече. Влезте наново', true);
                                setTimeout(() => {
                                    this.$router.push('/');

                                }, 2000);
                            }

                        } else if (err.response.status == 403) {
                            this.$router.push('/403');
                        }
                            // this._vm.showAlert(err.response.data.message, true);
                        reject(err);
                    })

                })
        },
        get(url) {
            return this.ajaxCall('GET', url)
        },
        post(url, params, alert = true) {
            return this.ajaxCall('POST', url, params, alert)
        },
        patch(url, params) {
            return this.ajaxCall('PATCH', url, params)
        },
        delete(url, params) {
            return this.ajaxCall('DELETE', url, params)
        },
        redirect(url) {
            window.location.replace(url);
        },
        redirectWithRouter(url) {
            this.$router.push(url);
        },
        convertDate(date, format = "DD-MM-YYYY") {
            moment.locale('bg')
            if (date != "") {
                let d = moment(date).format(format);
                return d;
            }
            return "";


        },
        showAlert(text, error = false) {
            let alert = {
                error: error,
                show: true,
                text: text
            }
            //this.$EventBus.$emit('alert',alert);
            this.$EventBus.$emit('alert', alert);
        },
        moment(val) {
            return moment(val);
        },
        showDialog(data = {}) {
            return new Promise(resolve => {
                data.resolve = resolve
                this.$EventBus.$emit('show-dialog', data);
            })
        },
        clear() {
            this.$EventBus.$emit('clear-all');
        },
        routeIsNot(route) {
            return this.$route.name !== route;
        },
    }
}

export default mix;
