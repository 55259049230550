<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col lg="6" md="12" sm="12">

          <h3 class="title text-center">Вход</h3>
          <v-form ref="loginForm" lazy-validation>
            <v-text-field
                v-model="user.email"
                label="Email"
                type="email"
                outlined
            >
            </v-text-field>


            <v-text-field
                label="Парола"
                type="password"
                v-model="user.password"
                outlined
                @keydown.enter="login(user)"
            >
            </v-text-field>

            <v-btn block color="primary" dark @click="login(user)">
              Вход
            </v-btn>

            <v-btn block color="primary" to="/register" outlined class="mt-5">
              Регистрация
            </v-btn>
            <v-btn block text to="/password-reset" class="mt-5">
              Забравена парола
            </v-btn>
          </v-form>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "Login",
  data: () => ({
    user: {
      email: '',
      password: ''
    }
  }),
  methods: {
    ...mapActions(['login'])
  }
}
</script>

<style scoped>

</style>