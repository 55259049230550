<template>
  <v-container fluid>
    <v-card>
      <v-row >
        <v-col cols="6">
          <v-card-title>Справки</v-card-title>
        </v-col>
        <v-col cols="6" class="pr-7">
          <v-text-field label="Търси" v-model="search" outlined ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="orders"
          @click:row="handleRowClick"
          class="row-pointer"
          :search="search"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="edit(item)">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn icon @click="deleteItem(item)">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.OperType="{ item }">
          <v-chip :color="parseInt(item.status) === 0?'orange':'green'" text-color="white">
            {{ parseInt(item.status) === 0 ? 'Обработва се' : 'Завършена' }}
          </v-chip>
        </template>
        <template v-slot:item.Date="{ item }">
          {{ convertDate(item.Date) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>

</template>

<script>
export default {
  name: "Orders",

  data: () => ({
    orders: [],
    search:'',
    headers: [
      {
        text: 'Поръчка №',
        align: 'start',
        sortable: true,
        value: 'Acct',
      },
      {
        text: 'Партньор',
        sortable: true,
        value: 'partner.Company',
      },
      {
        text: 'Дата',
        value: 'Date',
      },
      {
        text: 'Статус',
        value: 'OperType',
      },
    ]
  }),
  computed: {
    user: {
      get() {
        return this.$store.state.user
      }
    },
    loading:{
      get(){
        return this.$store.state.loading
      },
      set(val){
        this.$store.commit('setLoading',val)
      }
    },
  },
  methods: {
    edit(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    handleRowClick($evt) {
      this.redirectWithRouter(`/orders/order/${$evt.Acct}`)
    },
    fetchOrders() {
      let self = this;
      this.loading = true;
      this.get(`/orders/get`).then(r => {

        for (let i in r.orders)
          self.orders.push(r.orders[i])
        // = r.orders;
      }).catch(()=>{
        self.showAlert('Възникна грешка',true)
      }).finally(()=>{
        self.loading = false
      })
    },
  },
  mounted() {
    this.fetchOrders()
    // if (!this.user.partner_id) {
    //   this.
    // }
  }

}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>