<template>
  <v-container fluid>
    <app-edit-partner ref="editPartner"></app-edit-partner>

    <v-card>
      <v-row>
        <v-col>
          <v-card-title>
            Партньори
            <v-btn bottom color="primary" icon>
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-card-title>
        </v-col>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Търси"
              single-line
              hide-details
              outlined
              dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
          :headers="headers"
          :items="partners"
          :search="search"
      >
        <template v-slot:item.edit="{ item }">
          <v-btn icon @click="edit(item)">
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>

        </template>
        <template v-slot:item.userLevel="{ item }">
         {{role(item)}}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Partners",
  components: {
    appEditPartner: () => import('@/components/EditPartner/EditPartner')
  },
  data: () => ({
    partners: [],
    search:"",
    headers: [
      {
        text: 'Име',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: 'Клиент', value: 'hotel'},
      {text: 'Email', value: 'email'},
      {text: 'Склад Pro партньор', value: 'partner.Company'},
      {text: 'Роля', value: 'userLevel'},
      {text: 'Редактирай', value: 'edit'},
    ]
  }),
  computed:{
    user:{
      get(){
        return this.$store.state.user
      },
      set(val){
        this.$store.commit('setUser',val)
      }
    }
  },
  methods: {
    edit(item) {
      let self = this;
      if(item.group_id){
        item.group_id = item.group_id.toString()
      }
      this.$refs.editPartner.open(item).then(data=>{
        if(data){
          this.post(`/partners/update`,{
            user_id:item.id,
            partner_id:data.partner?data.partner.ID: null,
            group_id:data.group_id || null,
            is_active:data.is_active,
            userLevel:data.userLevel,
            name:data.name,
            email:data.email,
            hotel:data.hotel
          }).then(()=>{
            self.showAlert('Успешно обновен партньор')
          })
        }else{
          this.fetchPartners();
        }
      })
    },
    role(item){
     switch (item.userLevel){
       case 0:{
         return 'Клиент'
       }
       case 1:{
         return 'Управител'
       }
       case 2:{
         return 'Администратор'
       }
     }
    },
    fetchPartners() {
      let self = this;
      this.get('/partners/registered/get').then(r => {
        self.partners = r.partners
      })
    }
  },
  mounted() {
    this.fetchPartners()
  }
}
</script>

<style scoped>

</style>