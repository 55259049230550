<template>
  <v-card class="ma-auto" flat width="45vw">
    <h3 class="title text-center">Регистрация</h3>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field v-model="user.name" :rules=" [v => !!v || 'Името е задължително']" label="Име" outlined required
                      type="text">

        </v-text-field>
        <v-text-field v-model="user.hotel" :rules=" [v => !!v || 'Името е задължително']" label="Хотел" outlined required
                      type="text">

        </v-text-field>

        <v-text-field v-model="user.email" :rules=" [v => !!v || 'Email адресът е задължителен']" label="Email" outlined
                      required
                      type="email">

        </v-text-field>
        <v-text-field v-model="user.password" :rules=" [v => !!v || 'Паролата е задължителна']" label="Парола" outlined
                      required
                      type="password">

        </v-text-field>

      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block color="#31A4C1" dark @click="validate()">
        Продължи
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AddUser",
  data: () => ({
    valid: false,
    user: {
      name: '',
      email: '',
      hotel:'',
      password: ''
    },
  }),
  methods: {
    ...mapActions(['register']),
    validate() {
      let val = this.$refs.form.validate();
      if(val){
        this.register(this.user);
      }

    },
  },
}
</script>

<style scoped>

</style>