<template>
  <v-container fluid>
    <app-select-partner ref="selectPartnerDialog"></app-select-partner>
    <app-create-order ref="createOrderDialog"></app-create-order>
    <app-confirm-dialog ref="confirmDialog"></app-confirm-dialog>

    <v-card>
      <v-card-title>
        Нова поръчка
      </v-card-title>
      <v-list>
        <v-list-item>
          <v-btn block text color="primary" outlined @click="addOrder()" class="text-none">
            Добави услуга
          </v-btn>
        </v-list-item>
        <v-form v-model="valid" ref="form" lazy-validation>
          <template v-for="(order_item,i) in order_items">
            <v-list-item :key="i">
              <v-list-item-content>
                <v-list-item-title>
                  {{ order_item.Name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row no-gutters>
                  <v-col cols="10">
                    <v-text-field outlined v-model="order_item.quantity" label="Брой"
                                  :rules="[ v => !!v || 'Количеството е задължително',
                                            v => v > 0|| 'Стойността трябва да е по-голяма от 0'
                                            ]" type="number"
                                  step="any"></v-text-field>

                  </v-col>
                  <v-col cols="2" class="ma-auto">
                    <v-btn icon class="text-center mb-5" @click="removeItem(order_item)">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list-item v-if="order_items.length">
            <v-btn block text color="green" outlined @click="saveOrder()" class="text-none">
              Създай поръчка
            </v-btn>
          </v-list-item>
        </v-form>
      </v-list>

    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CreateOrder",
  components: {
    appCreateOrder: () => import('@/components/AddService/AddService'),
    appSelectPartner: () => import('@/components/SelectPartner/SelectPartner'),
    appConfirmDialog: () => import('@/components/ConfirmDialog/ConfirmDialog')
  },
  data: () => ({
    order_items: [],
    valid: true
  }),
  computed: {
    user: {
      get() {
        return this.$store.state.user
      },
      set(val) {
        this.$store.commit('setUser', val)
      }
    },
    loading: {
      get() {
        return this.$store.state.loading
      },
      set(val) {
        this.$store.commit('setLoading', val)
      }
    },
  },
  methods: {
    addOrder() {
      this.$refs.createOrderDialog.open(this.order_items).then(data => {
        console.log(data)
        if (data) {
          this.order_items = data;
        }
      })
    },
    saveOrder() {
      let self = this;
      this.$refs.confirmDialog.open('Желаете ли запис на поръчката?').then(r => {
        if (r) {
          let v = this.$refs.form.validate();
          if (v) {
            this.loading = true
            this.post(`/order/create`, {
              services: this.order_items,
              partner_id: this.user.partner_id
            }).then(() => {
              self.showAlert('Успешно създадена поръчка')
              self.redirectWithRouter('/orders')
              if (self.user.is_empty) {
                self.user.group_id = null
                self.user.partner_id = null
              }
            }).catch(() => {
              self.showAlert('Възникна грешка при създаването на поръчката', true)
            }).finally(() => {
              self.loading = false

            })
          }
        }
      })

    },
    selectPartnerOrders() {
      setTimeout(() => {
        this.$refs.selectPartnerDialog.open().then(() => {

        })

      }, 350)
    },
    removeItem(item){
      let index = this.order_items.indexOf(item);
      this.order_items.splice(index,1);
    }
  },

  created() {
    if (this.user.is_empty) {
      this.user.group_id = null
      this.user.partner_id = null
    }
    if (!this.user.group_id) {
      this.selectPartnerOrders();
    }
  },
  watch: {
    user(val) {
      if (!val.group_id || val.partner_id) {
        this.selectPartnerOrders();
      }
    }

  }
}
</script>

<style scoped>

</style>