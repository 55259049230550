<template>
  <v-container>
  <h1 class="title">Нямаш достъп до тази страница</h1>
  </v-container>
</template>

<script>
export default {
  name: "Forbidden"
}
</script>

<style scoped>

</style>