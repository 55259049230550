import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from "axios";




import mix from "@/mixins/mixin";

Vue.mixin(mix)

axios.defaults.baseURL = 'https://backend.industrialnoprane.bg/api/auth/'
// axios.defaults.baseURL = 'http://webform.backend.com/api/auth/'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/auth/'


Vue.prototype.$EventBus = new Vue()


Vue.config.productionTip = false


//axios interceptors

// let isRefreshing = false
// let subscribers = [];
// let count = 0;
// axios.interceptors.response.use(response => {
//         return response
//     }, err => {
//         const {
//             config,
//             // eslint-disable-next-line no-unused-vars
//             response: {status, data}
//         } = err
//
//         if(count > 1){
//             router.push('/');
//           //  alert('Възникна грешка')
//             return Promise.reject(false)
//
//         }
//
//         count++;
//
//         const originalRequest = config;
//         if (originalRequest.url.includes('/login')||originalRequest.url.includes('/me')) {
//             return Promise.reject(false)
//         }
//
//         if (!localStorage.access_token) {
//             router.push('/');
//             return Promise.reject(false)
//         }
//
//         if (status === 401) {
//             if (!isRefreshing) {
//                 isRefreshing = true;
//                 store.dispatch('refreshToken').then(({status}) => {
//                     if (status === 200 && status === 204) {
//                         isRefreshing = false
//                     }
//                     subscribers = []
//                 }).catch(err => {
//                     console.log(err);
//                 });
//             }
//
//             const requestSubscribers = new Promise(resolve => {
//                 subscribeTokenRefresh(() => {
//                     resolve(axios(originalRequest))
//                 })
//             })
//             onRefreshed();
//             return requestSubscribers;
//         }
//     }
// )
//
// function subscribeTokenRefresh(cb) {
//     subscribers.push(cb);
// }
//
// function onRefreshed() {
//     subscribers.map(cb => cb())
// }
//
// subscribers = [];

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
