<template>
  <v-container fluid>

          <app-add-user></app-add-user>

  </v-container>
</template>

<script>
import AddUser from "@/components/AddUser/AddUser";


export default {
  name: "Register",
  components:{
    appAddUser:AddUser,
  },

}
</script>

<style scoped>

</style>